/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/fonts/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/fonts/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/fonts/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* TO DEBUG SCROLLING */
  /* outline: 1px solid red; */
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px 34px;
  font-size: 18px;
  margin-left: 18px;
  margin-top: 5px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.img_rotate {
  width: 90%;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

.dropdown {
  margin-left: 5px;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-align: justify;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  margin-bottom: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 4s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/

/* Skills Container */
.skills-container {
  padding: 2.5rem 1.25rem; /* px-5 py-10 */
  margin: 0 auto; /* mx-auto */
}

/* Skills Header */
.skills-header {
  text-align: center;
  margin-bottom: 5rem; /* mb-20 */
}

/* Skills Icon */
.skills-icon {
  width: 2.5rem; /* w-10 */
  display: inline-block;
  margin-bottom: 1rem; /* mb-4 */
}

/* Skills Title */
.skills-title {
  font-size: 1.875rem; /* text-3xl */
  font-weight: 500; /* font-medium */
  color: white; /* text-white */
  margin-bottom: 1rem; /* mb-4 */
}

@media (min-width: 640px) {
  .skills-title {
    font-size: 2.25rem; /* sm:text-4xl */
  }
}

/* Skills Description */
.skills-description {
  font-size: 1rem; /* text-base */
  line-height: 1.75; /* leading-relaxed */
  margin: 0 auto; /* mx-auto */
  width: 100%; /* xl:w-2/4 */
  text-align: justify;
}

@media (min-width: 1024px) {
  .skills-description {
    width: 75%; /* lg:w-3/4 */
  }
}

/* Skills List */
.skills-list {
  display: flex;
  flex-wrap: wrap; 
  width: 100%; 
}


@media (min-width: 640px) {
  .skills-list .skill-item {
    width: 50%; /* sm:w-1/2 */
  }
}

/* Skill Item */
.skill-item {
  flex: 0 0 auto;
  padding: 0.5rem; /* p-2 */
  width: 100%; /* w-full */
}

/* Skill Card */
.skill-card {
  background-color: #1F2937; /* bg-gray-800 */
  border-radius: 0.25rem; /* rounded */
  display: flex;
  align-items: center; /* items-center */
  padding: 1rem; /* p-4 */
  /* height: 100%; */
}

/* Skill Icon */
.skill-icon {
  color: #48BB78; /* text-green-400 */
  width: 1.5rem; /* w-6 */
  height: 1.5rem; /* h-6 */
  flex-shrink: 0; /* flex-shrink-0 */
  margin-right: 1rem; /* mr-4 */
}

/* Skill Text */
.skill-text {
  font-weight: 500; /* font-medium */
  color: white; /* text-white */
}


/************ Fiverr Css ************/

.fiverr-badge {
  padding: 80px 0;
  position: relative;
}

.fiverr-badge h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}

.fiverr-badge button {
	font-size: 25px;
	font-weight: 700;
  text-align: center;
  background-color:  #1DBF73;
  border-color:  #1DBF73;
}

.fiverr-badge button:hover {
	font-size: 25px;
	font-weight: 700;
  text-align: center;
  background-color:  #00b22d;
  border-color:  #1DBF73;
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  /* color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%; */
  font-size: 1rem;
  line-height: 1.75;
  margin: 0 auto;
  width: 100%;
  margin: 14px auto 30px auto;
  text-align: justify;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  /* width: 33.33333%; */
  width: 25%;
}

@media (max-width: 575px) {
  .project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 13px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
}

@media (min-width: 576px) {
  .project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-wordpress {
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  color: #fff;
}

.proj-img {
  height: 270px; /* Set the desired height */
  width: auto;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.contact form button:hover::before {
  width: 100%;
}

/************ About Css ************/

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: black;
  position: relative;
}

.about-left-content {
  flex: 1;
  padding: 150px;
}

.about-right-content {
  flex: 1;
  text-align: right;
}

.about-right-content img {
  max-width: 75%;
  height: auto;
}

.about-paragraph {
  text-align: justify;
}

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column; 
    text-align: center; /* Center align the text */
  }

  .about-left-content,
  .about-right-content {
    flex: none; /* Remove flex properties so they stack on top of each other */
    padding: 20px; /* Adjust the padding as needed */
  }

  .about-right-content img {
    max-width: 100%; /* Make the image full-width */
  }
}

/************ Gallery Css ************/

.gallery_body {
  display: grid;
  place-items: center;
  height: 50vh;
}

.gallery{
  display: flex;
  gap: 1rem;
  max-width: 80rem;
  justify-content: center;
}

.card {
  position: relative;
  left: 0px;
  width: 40rem;
  border: none !important;
  border-radius: 1.2rem;
  height: 20rem;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
  flex: 0.25;
}

.card img {
  position: relative;
  height: 20rem;
  object-fit: cover;
}

.card:hover {
  flex: 2;
  font-weight: bold;
  cursor: pointer;
  border-radius: 1rem;
}

/* Responsive styles for mobile devices */
@media (max-width: 600px) {
  .gallery_body {
    height: auto;
  }

  .gallery {
    flex-direction: column; /* Stack cards in a column */
  }

  .card {
    width: 100%; /* Full width cards on smaller screens */
    height: auto; /* Adjust height automatically */
    flex: none; /* Disable flex grow/shrink */
  }

  .card:hover {
    flex: none;
  }
}


/************ Footer Css ************/
.footer {
  padding: 20px 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* Responsive styles for mobile devices */
@media (max-width: 600px) {
.social-icon-footer {
  display: inline-block;
  margin-left: 14px;
  width: 90% !important;
}
}
.social-icon-footer a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon-footer a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover::before {
  transform: scale(1);
}
.social-icon-footer a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/************ Blog Css ************/

.blog-post {
  width: 80%;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.blog-title {
  font-size: 2em;
  color: #333;
}

.blog-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.blog-description {
  font-size: 1em;
  margin-top: 20px;
  color: #333;
}

.code-section {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.code-section pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #000;
}

.blog-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blog-button-cta {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/************ Articles Css ************/

.articles-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 articles per row */
  grid-gap: 20px;
  padding: 20px;
  margin-top: 150px;
}

@media (max-width: 768px) {
  .articles-grid {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }
}

.article-title {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.article-description {
  font-size: 1em;
  margin-top: 20px;
  color: #333;
}

.article-card {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.article-card img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.article-read-more {
  display: inline-block;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
}

/************ Loader Css ************/

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/************ SERVICES ************/

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    margin: 1rem;
    align-items: center !important
  } 
}

.service {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  color: #fff;
  background-color: black;
}

.service h1 {
  font-size: 45px;
  margin-bottom: none;
}

.services-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: black;
  color: #333;
  align-items: flex-start
}

.services-header {
  text-align: center;
  color: white;
  margin-bottom: 30px;  
  background-color: black;
}

.service-sections {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* .service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  width: 45%;
  position: relative;
  min-height: 100%;
} */

.service h2, .service p, .accordion-button {
  color: #1a1a1a;
}

.accordion-button {
  background: none;
  border-bottom: 1px solid #000 !important;
  border-color: transparent;
  color: #000;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-top: 10px;
}

.accordion-button:focus {
  outline: 2px solid transparent;  /* Example: Blue outline to match a theme color */
    outline-offset: 2px;
}

.accordion-content {
  padding: 20px 0;
  border-top: 1px solid #eee;
}
/* 
.service p, .accordion-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
} */

/************ APP_CGV Css ************/

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10rem;
}

.form-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.form-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.form-field {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  box-sizing: border-box;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
}

.success-message {
  color: green;
  margin-top: 1rem;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 1rem;
  text-align: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.retry-button {
  display: block;
  margin: 1rem auto;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
}