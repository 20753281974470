/* src/LandingPage.css */
.landing-page {
  display: flex;
  height: 100vh;
}
  
.image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-align: center;
}
  
.img-landing {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
  padding: 30px 35px;
}

@media (max-width: 768px) {
  /* Apply styles for screens smaller than 768px (e.g., phones) */
  .landing-page {
    flex-direction: column; /* Stack items vertically */
  }
}
  