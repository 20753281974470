.main {
    font-family: sans-serif;
    text-align: center;
    padding: 20px;
    margin-top: 100px;
}

/* Header info */
.first-section {
    margin-top: 25vh;
    margin-left: 30vh;
    display: flex;
    margin-bottom: 5vh;
}

.btn {
    margin: 10px 0 10px 0;
}

/* Carousel */
.carousel-item {
    margin-left: 50px;
}

.carousel-item:last-child {
    margin-right: 0;
}

.carousel-section {
    margin-left: 200px;
    margin-right: 200px;
}

/* Quote with image */
.quote-section {
    position: relative;
}

.quote-section img{
    max-width: 100% ;
    height: 300px;
    object-fit: cover;
}

.quote-section p{
    margin-left: 30vh;
    margin-right: 30vh;
    position: absolute;
    transform:translate(0%, -170%) ;
    text-align: center;
    font-size: 1.5rem;
}

/* Developer jobs section */
.developer-jobs-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 10vh;
    margin-left: 30vh;
    margin-right: 30vh;
}

.grid-item {
    height: 400px; 
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
}

.grid-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; /* Center the text horizontally */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    height: 400px;
    width: 500px;
}

.grid-item.with-background:hover .grid-content {
    background-color: rgba(104, 76, 76, 0.5);
}

.grid-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.grid-content p {
    margin-left: 10vh;
    margin-right: 10vh;
    font-size: 1rem;
    color: white;
}

/* Hire processus section */
.processus-section-header {
    margin-top: 10vh;
    margin-left: 10vh;
    margin-right: 10vh;
    align-items: center; 
}

.processus-section {
    margin-top: 5vh;
    margin-left: 15vh;
    margin-right: 15vh;
    display: flex;
    align-items: center; 
}

.review,
.technical,
.assessment {
    flex: 1;
    text-align: justify;
    margin-right: 5vh;
    margin-left: 5vh;
}

/* Hire btn section */
.btn-section {
    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 10vh;
    margin-right: 10vh;
    text-align: center;
}
  

/****************************/


@media only screen and (max-width: 768px) {
    /* ... (your existing media query styles) */

    /* Header info */
    .first-section {
        margin: 10vh 5vw; /* Adjust margin for smaller screens */
    }

    .first-section Button{
        display: none;
    }

    /* Update styles for small screens here */
    .carousel-section {
        display: none;
    }

    .quote-section {
        display: none; /* Hide the quote-section on smaller screens */
    }

    .developer-jobs-section {
        grid-template-columns: 1fr;
        margin-left: 5px;
        margin-right: 5px;
    }

    .grid-item {
        height: 200px;
    }

    .grid-content h2 {
        font-size: 1.2rem;
    }

    .grid-content p {
        font-size: 0.9rem;
    }

    .processus-section-header img {
        display: none;
    }

    .processus-section {
        flex-direction: column; /* Change flex direction to column for smaller screens */
        align-items: flex-start; /* Align items to the start of the flex container */
        margin-left: auto;
        margin-right: auto;
    }

    .review,
    .technical,
    .assessment {
        margin-left: 10px; /* Add margin between the sections */
        margin-right: 10px;
        width: auto; /* Reset width to auto for original width */
    }


    .btn-section {
        margin-left: 5px;
        margin-right: 5px;
    }
}
